//
// buttons.scss
//
.btn {
    color: $white;
    padding: 0.65rem 0.9rem;
    &:focus {
        box-shadow: none;
    }
    .icon {
        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.1rem;
            margin-top: -2px;
        }
    }
}

//
// light button
//
.btn-light,
.btn-white {
    color: $gray-900;
}

.btn-white {
    border-color: $gray-300;
    &:hover,
    &:focus {
        background-color: $gray-300;
        border-color: $gray-400;
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
}

//
// Button Extra Small Size
//
.btn-xs {
    padding: 0.2rem 0.6rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
}

// Soft variants
@mixin btn-variant-soft($bg, $color) {
    background-color: rgba($bg, $btn-soft-bg-opacity);
    color: $color;
    border-color: rgba($color, $btn-soft-bg-opacity);
    &:focus {
        box-shadow: none;
    }
    &:hover,
    &:focus {
        background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), 0.05);
        color: $color;
    }
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        @include btn-variant-soft($value, $value);
    }
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
        &:focus {
            box-shadow: none;
        }
    }
}