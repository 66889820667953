//
// general.scss
//
html {
  position: relative;
  min-height: 100%;
}

.kol3 .none {
  display: none !important;
}

.kol3 .btn {
  border-radius: 8px;
}

body {
  overflow-x: hidden;
  color: var(--primarios-neutrales-neutral-500, #667085);
}

.side-menu ion-icon {
  font-size: 1.5rem;
  vertical-align: middle;
}

#sidebar-menu .menu-title {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

#sidebar-menu > ul > li > a > span {
  padding-left: 4px;
  line-height: 19px;
}

#sidebar-menu > ul > li > .menucheck > span {
  padding-left: 4px;
  line-height: 19px;
}

#sidebar-menu > ul > li.menuitem-active > a > span {
  font-weight: 700;
}

.icon-kol3 {
  display: inline-block;
  width: 23px;
  height: 23px;
  contain: strict;
  fill: currentcolor;
  box-sizing: content-box !important;
  background-size: cover;
  vertical-align: middle;
  margin-bottom: 2px;
}

.icon-kol3.i-averias {
  background-image: url("/assets/images/tools.svg");
}

body[data-sidebar-color="light"] #light-check {
  display: none;
}

body[data-sidebar-color="dark"] #light-check {
  background-position: right center;
  transition: background-position 0.15s ease-in-out;
}

body[data-sidebar-color="dark"] .menucheck {
  color: rgba(255, 255, 255, 0.72);
}

body[data-sidebar-color="dark"] .side-menu .icon-kol3.i-averias {
  background-image: url("/assets/images/tools_w.svg");
}

.menuitem-active .icon-kol3.i-averias {
  background-image: url("/assets/images/tools_b.svg");
}

body[data-sidebar-color="dark"]
  .left-side-menu
  #sidebar-menu
  > ul
  > li
  > a
  ion-icon {
  color: #ffffff;
}

.left-side-menu {
  padding: 0px;
}

.menucheck {
  padding: 12px 25px;
}

.inline-b {
  display: inline-block;
}

.card .h-t-i {
  display: inline-block;
  width: 33px;
  height: 33px;
  position: relative;
  top: -6px;
  margin-right: 10px;
}

.card {
  border: 0px;
  border-radius: 8px !important;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(71, 84, 103, 0.16);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  .card-title {
    margin-top: 0;
    font-family: Mulish;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    color: var(--primarios-neutrales-neutral-900, #101828);
  }
  .card-body2 {
    flex: 1 1 auto;
    padding: 0.5rem 1.25rem;
  }
  h4 {
    margin: 0px !important;
  }
  hr {
    margin: 0.8rem 0;
  }
  .link-all {
    color: var(--primarios-neutrales-neutral-500, #667085);
  }
  ion-icon.iconLeft.float-end.h-t-i {
    top: 7px;
    color: var(--primarios-neutrales-neutral-900, #101828);
    margin-right: 0px;
  }
}

.kol3 .card-detalle {
  hr {
    margin: 0.7rem 0;
  }
}

.navbar-custom {
  border-bottom: 3px solid $color-secundario !important;
}

.link-all {
  color: inherit;
}

.page-title-box-kol3 {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin: 0 -27px 0;
  padding: 20px 27px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.btn-outline-p {
  border: 1px solid var(--primarios-neutrales-neutral-400, #98a2b3);
  background: #fff;
  color: var(--primarios-neutrales-neutral-600, #475467);
}

.btn-kol3 {
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(71, 84, 103, 0.16);
}

.btn-icon-kol3 {
  padding: 0.52rem 0.9rem;
  padding-right: 25px !important;
}

.btn-primary-small {
  background: var(--primarios-primario-primario-50, #c1d5e6);
  color: var(--primarios-primario-primario-500, $color-primario);
}

.btn ion-icon {
  margin: 0px;
  position: relative;
  top: 4px;
  font-size: 1.2rem;
}

.text-sm {
  font-size: 0.7rem;
}

.text-b {
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

i.uil.md-s {
  font-size: 1.2rem;
  position: relative;
  top: -2px;
}

.diviniciales {
  display: inline-flex;
  align-items: center;
  background: $color-secundario;
  font-weight: bold;
  color: #ffffff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 0.9rem;
  justify-content: center;
}

#sidebar-menu > ul > li > a {
  padding: 12px 16px;
  margin: 0px 9px;
}

li.side-nav-item {
  margin-bottom: 15px;
}

#sidebar-menu > ul > li.menuitem-active {
  position: relative;
  > a {
    color: $color-primario !important;
    background: var(--primarios-primario-primario-25, #e6eef5);
    border-radius: 8px;
  }
  &:after {
    content: "";
    width: 80%;
    margin-left: 10%;
    height: 2px;
    position: absolute;
    bottom: -6px;
    background: $color-primario;
  }
}

body.kol3[data-sidebar-color="dark"] #sidebar-menu > ul > li.menuitem-active {
  position: relative;
  > a {
    color: #fff !important;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.16);
  }
  &:after {
    content: "";
    width: 80%;
    margin-left: 10%;
    height: 2px;
    position: absolute;
    bottom: -6px;
    background: #fff;
  }
}

.kol3[data-sidebar-size="condensed"] #sidebar-menu {
  .iconLeft {
    display: none;
  }
  .icon-kol3 {
    display: inline-block !important;
    padding: 0px !important;
  }
  .side-nav-link-ref,
  .side-sub-nav-link {
    padding-left: 15px;
  }
  .menucheck {
    span {
      display: none;
    }
    margin: 0px 0px;
  }
}

.kol3 .profile-dropdown {
  min-width: 290px !important;
}

.kol3 .dropdown-filtros {
  min-width: 290px !important;
  max-height: 90vh;
  z-index: 2001;
  overflow: auto;
}

.kol3 .radio-botton-left {
  padding-left: 0rem;
  padding: 0.65rem 0.9rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px rgba(71, 84, 103, 0.16);
  .form-check-input {
    float: right;
    margin-left: -1.5rem;
  }
}

.kol3 .check-botton-left {
  padding-left: 0rem;
  padding: 0.65rem 0.9rem;
  .form-check-input {
    float: right;
    margin-left: -1.5rem;
  }
}

.kol3 .menucheck {
  .form-switch {
    padding: 0px;
    .form-check-input {
      width: 1.6em;
      margin-left: -2.5em;
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/></svg>");
      background-position: left center;
      border-radius: 2em;
      transition: background-position 0.15s ease-in-out;
      height: 0.8rem;
      background-color: #667085;
      float: right;
    }
  }
}

body.kol3[data-sidebar-color="dark"] .menucheck {
  .form-switch {
    padding: 0px;
    .form-check-input {
      width: 1.6em;
      margin-left: -2.5em;
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27rgba%286, 87, 153, 1%29%27/></svg>");
      background-position: left center;
      border-radius: 2em;
      transition: background-position 0.15s ease-in-out;
      height: 0.8rem;
      background-color: #fff;
      float: right;
    }
  }
}

body[data-sidebar-color="dark"] .logo-box .logo-light {
  display: block;
}

/* estados */

.estados-c {
  background-color: #ccc;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  &.estado-1 {
    background: var(--secundarios-marron-marron-50, #f6efe9);
    color: var(--secundarios-marron-marron-600, #9b5d25);
  }
  &.estado-2 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--secundarios-azul-azul-600, #1657c8);
  }
  &.estado-3 {
    background: var(--primarios-neutrales-neutral-100, #f2f4f7);
    color: var(--primarios-neutrales-neutral-600, #475467);
  }
  &.estado-4 {
    background: var(--secundarios-rojo-rojo-50, #fae8e8);
    color: var(--secundarios-rojo-rojo-600, #bf1616);
  }
  &.estado-5 {
    background: var(--secundarios-purpura-purpura-50, #f0ecf9);
    color: var(--secundarios-purpura-purpura-600, #643ebc);
  }
  &.estado-6 {
    background: var(--secundarios-verde-verde-50, #e6f1ec);
    color: var(--secundarios-verde-verde-600, #026f42);
  }
}

.estados-u {
  background-color: #ccc;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  &.estado-1 {
    background: var(--primarios-exito-exito-100, #d1fadf);
    color: var(--primarios-exito-exito-700, #027a48);
    &::before {
      content: "\e8d6";
    }
  }
  &.estado-2 {
    background: var(--primarios-neutrales-neutral-100, #f2f4f7);
    color: var(--primarios-neutrales-neutral-600, #475467);
    &::before {
      content: "\ebec";
    }
  }
  &.estado-3 {
    background: var(--primarios-neutrales-neutral-100, #f2f4f7);
    color: var(--primarios-neutrales-neutral-600, #475467);
  }
  &.estado-4 {
    background: var(--secundarios-rojo-rojo-50, #fae8e8);
    color: var(--secundarios-rojo-rojo-600, #bf1616);
  }
  &.estado-5 {
    background: var(--secundarios-purpura-purpura-50, #f0ecf9);
    color: var(--secundarios-purpura-purpura-600, #643ebc);
  }
  &.estado-6 {
    background: var(--secundarios-verde-verde-50, #e6f1ec);
    color: var(--secundarios-verde-verde-600, #026f42);
  }
  &::before {
    font-family: "unicons";
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    vertical-align: middle;
    font-size: 120%;
    -webkit-font-smoothing: antialiased;
    margin-right: 3px;
  }
}

.criticidad-c {
  background-color: #ccc;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  min-width: 82px;
  display: inline-block;
  &.criticidad-1 {
    background: var(--primarios-neutrales-neutral-100, #f2f4f7);
    color: var(--primarios-neutrales-neutral-600, #475467);
  }
  &.criticidad-2 {
    background: var(--primarios-exito-exito-100, #d1fadf);
    color: var(--primarios-exito-exito-600, #039855);
    &::before {
      content: "\e9c2";
    }
  }
  &.criticidad-3 {
    background: var(--primarios-advertencia-advertencia-100, #fef0c7);
    color: var(--primarios-advertencia-advertencia-600, #dc6803);
    &::before {
      content: "\e9c2";
    }
  }
  &.criticidad-4 {
    background: var(--primarios-error-error-100, #fee4e2);
    color: var(--primarios-error-error-600, #d92d20);
    &::before {
      content: "\e9c2";
    }
  }
  &::before {
    font-family: "unicons";
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    vertical-align: middle;
    font-size: 120%;
    -webkit-font-smoothing: antialiased;
    margin-right: 3px;
  }
}

.tipo-c {
  background-color: #ccc;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  &.tipo-1 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--primarios-primario-primario-900, #021e36);
  }
  &.tipo-2 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--primarios-primario-primario-900, #021e36);
  }
  &.tipo-3 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--primarios-primario-primario-900, #021e36);
  }
  &.tipo-4 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--primarios-primario-primario-900, #021e36);
  }
}

.rol-c {
  background-color: #ccc;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  &.rol-1 {
    background: var(--secundarios-purpura-purpura-50, #f0ecf9);
    color: var(--secundarios-purpura-purpura-600, #643ebc);
  }
  &.rol-2 {
    background: var(--secundarios-azul-azul-50, #e8effb);
    color: var(--primarios-primario-primario-900, #021e36);
  }
}

.btn-sinlinea {
  border: none;
  background: unset;
  padding: 0.3rem;
}

.btn-vacio {
  color: #4b4b5a;
}

.dropdown-small-kol3 {
  width: 230px;
  .dropdown-item {
    white-space: unset;
  }
  .iconleft-kol3 {
    float: left;
    font-size: 1.4rem;
    padding-top: 6px;
    padding-right: 5px;
  }
}

.encabezado-interior {
  position: fixed;
  z-index: 1042;
  width: 100%;
  background: #fff;
  left: 0px;
  top: 0;
  padding: 10px 20px;
  border-bottom: 5px solid #f60;
  .dropstart {
    > button {
      padding: 0px;
      &:hover {
        background-color: unset !important;
        color: unset !important;
      }
    }
  }
  .link-all {
    font-size: 0.7rem;
    padding: 10px 1px;
  }
}

.bg-none {
  background-color: unset;
  border: unset;
}

.center-title .modal-header {
  justify-content: center;
  .modal-title {
    font-weight: 700;
  }
}

.movil {
  display: none !important;
}

.desktop {
  display: block !important;
}

body.kol3[data-sidebar-color="dark"] .left-side-menu {
  background: var(--primarios-primario-primario-500, $color-primario);
  border-right-color: $color-primario;
  box-shadow: none;
}

body.kol3[data-sidebar-color="dark"] .logo-box {
  background-color: $color-primario !important;
  border-right: 1px solid$color-primario !important;
}

body.kol3[data-sidebar-color="dark"] .left-side-menu #sidebar-menu .menu-title {
  color: rgba(255, 255, 255, 0.72);
  color: #fff;
}

body.kol3[data-sidebar-color="dark"]
  .left-side-menu
  #sidebar-menu
  > ul
  > li
  > a {
  color: rgba(255, 255, 255, 0.72);
}

body.kol3[data-sidebar-color="dark"] li.menu-title.mt-2 {
  color: #fff;
}

.kol3 .text-normal {
  font-size: 0.9rem;
}

.sindatos {
  min-height: 630px;
  display: flex;
  justify-content: center;
  align-items: center;
  .sincotenido {
    text-align: center;
    width: 270px;
  }
  .text {
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--primarios-neutrales-neutral-400, #98a2b3);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
  }
}

.droplink-kol3 > a,
.linkmovil {
  padding: 3px 8px;
}

.kol3 .logo-box {
  .logo {
    line-height: 65px;
  }
}

.content {
  position: relative;
}

.toast.kol3 {
  position: fixed;
  right: 24px;
  top: 90px;
  z-index: 999999;
}

.toast.kol3.error {
  background: var(--primarios-advertencia-advertencia-500, #f79009);
  /* Shadow light */
  box-shadow: 0px 4px 16px 0px rgba(71, 84, 103, 0.16);
  .toast-body {
    color: #fff;
    text-align: center;
  }
}

.editaveria-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  background-color: #f8f9fa;
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.edit-preview-kol3 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
  .dz-details {
    overflow: hidden;
    width: 120px;
  }
  .dz-filename {
    white-space: nowrap;
    font-size: 12px;
  }
}

.validateerror {
  border: 2px solid #ff8282;
  border-radius: 10px;
}

@include media-breakpoint-down(md) {
  .movil {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .movil {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
}

@media (max-width: 2330px) {
  .dvmax1300 {
    display: none !important;
  }
  .dvmin1300 {
    display: block !important;
  }
  .table-averias .dvmin1300 {
    opacity: 0;
  }
  .table-averias tr:hover {
    background: #f1f1f1;
    .dvmin1300 {
      opacity: 1;
    }
  }
}

@media (max-width: 1400px) {
  .dvmax1300 {
    display: block !important;
  }
  .dvmin1300 {
    display: none !important;
  }
}

@media (max-width: 990px) {
  .kol3 .logo-box {
    display: block;
    background: unset !important;
    border: none !important;
    width: 240px !important;
    span.logo-sm {
      display: none !important;
    }
    span.logo-lg {
      display: block !important;
    }
  }
  .kol3 .navbar-custom {
    .logo-box {
      padding-left: 8px !important;
      text-align: left !important;
    }
  }
  .movil {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
}

@media (max-width: 630px) {
  .dvmax1300 {
    display: block !important;
  }
  .dvmin1300 {
    display: none !important;
  }
  .kol3.authentication-bg {
    background-image: unset;
    background: var(--gray-1, #f8f9fa);
  }
  .kol3 .account-pages {
    background: var(--gray-1, #f8f9fa);
  }
  .kol3 .account-pages .card {
    border: none;
    box-shadow: unset;
    .card-body.p-4 {
      padding: 0px !important;
    }
  }
  h3.page-title-modulo {
    font-size: 1rem;
  }
}
