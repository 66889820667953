//
// authentication.scss
//
// authentication pages background
body {
  &.authentication-bg {
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 0;
    background-image: url("../../../images/back_login.jpg");
  }
}

.authentication-form {
  .form-group {
    .input-group-text {
      background-color: transparent;
      background-color: unset;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.card-av {
  background: rgba(252, 252, 253, 0.9);
}

.input-group-text {
  background-color: #fff;
  padding-right: 2px;
  font-size: 1.4rem;
  border-radius: 0.55rem;
}

.input-group > .form-control,
.input-group > .form-select {
  border-left-width: 0px;
}

.icon-fb {
  color: #3c5a98;
}

.icon-google {
  color: #e94134;
}

.auth-page-sidebar {
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  .overlay {
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($black, 0.4);
  }
  .auth-user-testimonial {
    text-align: center;
    color: $white;
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;
  }
}

// auth-logo
.auth-logo {
  .logo-light {
    display: $logo-auth-light-display !important;
  }
  .logo-dark {
    display: $logo-auth-dark-display !important;
  }
}

// logout
.logout-checkmark {
  width: 90px;
  margin: 0 auto;
  padding: 20px 0;
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 2s ease-in-out;
  }
  .spin {
    animation: spin 2s;
    transform-origin: 50% 50%;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
